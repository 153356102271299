






































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseDataModule from '@/store/modules/base-data';
import MeetingModule from '@/store/modules/meeting';
import MeetingInfo from '@/store/entities/meeting-info';
import Anchor from '@/views/components/Anchor.vue';
import Collective from '@/views/components/Collective.vue';
import OrganInfo from '@/store/entities/organ-info';
import { StateEnum } from '@/store/entities/state-enum';
import { datetimeFormat } from '@/util/date-util';

@Component({
  components: {
    Anchor,
    Collective
  }
})
export default class EditSingleDraft extends Vue {
  @Prop({ default: false }) isShow!: boolean;
  @Prop({ type: Number, required: true }) id!: number;

  state: any = StateEnum;
  ruleForm: MeetingInfo = new MeetingInfo();
  availableRoomList: Array<any> = [];

  showAlert: boolean = false;
  showAnchor: boolean = false;
  showCollective: boolean = false;
  showInformer: boolean = false;
  arLoading: boolean = false;
  submitLoading: boolean = false;
  saveLoading: boolean = false;
  showCkJoinOrg: boolean = true;

  get orgList() {
    return BaseDataModule.orgList;
  }

  get currentUser() {
    return BaseDataModule.currentUser;
  }

  get meetingTypeList() {
    return BaseDataModule.meetingTypeList;
  }

  async save(status: StateEnum) {
    (this.$refs.ruleForm as any).validate((valid: boolean) => {
      if (valid) {
        if (this.ruleForm.attendees.length <= 1) {
          this.$confirm('建议您完整选择本次会议的参会人，以便排查您召集的参会人是否存在日程冲突...', '提示', {
            confirmButtonText: '选择无误,继续提交',
            cancelButtonText: '我再想想',
            type: 'warning'
          })
            .then(() => {
              this.saveSpecificOperation(status);
            })
            .catch(() => {
              return;
            });
        } else {
          this.saveSpecificOperation(status);
        }
      }
    });
  }

  async saveSpecificOperation(status: StateEnum) {
    let _message = '';
    if (status == StateEnum.Draft) {
      this.saveLoading = true;
      _message = '确认保存草稿？';
    } else {
      this.submitLoading = true;
      _message = '确认提交审批？';
    }

    this.ruleForm.lastModifierUserId = Number(this.currentUser.employeeID);
    this.ruleForm.status = status;
    this.ruleForm.subscriberId = Number(this.currentUser.employeeID);
    this.ruleForm.subscriberName = this.currentUser.employeeName;
    this.ruleForm.approvalOrgName = (this.orgList.find((x) => x.id === this.ruleForm.approvalOrgID) as OrganInfo)?.shortName;
    this.ruleForm.relationRooms = this.availableRoomList
      .filter((x) => this.ruleForm.ckIds.indexOf(x.id) > -1)
      .map((t) => ({
        key: t.id,
        value: t.name
      }));

    this.$confirm(_message, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(async () => {
        this.ruleForm.meetingMode = 1;
        await MeetingModule.atTheSameTime(this.ruleForm).then(async (res) => {
          if (res) {
            let remindMsg = `${res}在此时间段（${this.ruleForm.beginTime}~${this.ruleForm.endTime}）需要参加其他会议（时间冲突）,是否继续提交？`;
            this.$confirm(remindMsg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(async () => {
                await this.editMeeting();
              })
              .catch(() => {
                this.saveLoading = false;
                this.submitLoading = false;
              });
          } else {
            await this.editMeeting();
          }
        });
      })
      .catch(() => {
        this.saveLoading = false;
        this.submitLoading = false;
      });
  }

  async editMeeting() {
    await MeetingModule.edit(this.ruleForm)
      .then((res) => {
        this.$message.success('操作成功');
        this.$emit('refreshData');
        this.close();
      })
      .finally(() => {
        this.saveLoading = false;
        this.submitLoading = false;
      });
  }

  async selectAvailableRoom() {
    let _orgIds = this.ruleForm.joinOrgID.length <= 0 ? this.ruleForm.joinSingleOrgID : this.ruleForm.joinOrgID.join(',');
    if (this.ruleForm.meetingType <= 0 || !_orgIds || !this.ruleForm.singleDate) return;

    this.availableRoomList.splice(0);
    if (!!this.ruleForm.meetingType && !!this.ruleForm.singleDate && (this.ruleForm.joinOrgID.length > 0 || this.ruleForm.joinSingleOrgID > 0)) {
      this.arLoading = true;

      await MeetingModule.getAvailableRoomList({
        meetingType: this.ruleForm.meetingType,
        date: this.ruleForm.singleDate,
        organIds: _orgIds
      }).then((res) => {
        this.availableRoomList = res;
        setTimeout(() => {
          this.arLoading = false;
        }, 1000);
      });
    }
  }

  emptyAttendee() {
    this.ruleForm.attendees.splice(0);
    (this.$refs.ruleForm as any).validateField('attendeeProp');
  }

  disableSingleDate(eachDate: Date) {
    let now = datetimeFormat(new Date()) + ' 00:00:00';
    let dNow = new Date(now);
    return eachDate < dNow;
  }

  chjdChange(val: any) {
    this.availableRoomList.splice(0);
    this.selectAvailableRoom();
  }

  meetingTypeChange(val: any) {
    this.availableRoomList.splice(0);

    if (val == 1) {
      this.showCkJoinOrg = true;
      this.ruleForm.joinSingleOrgID = 0;
    } else {
      this.showCkJoinOrg = false;
      this.ruleForm.joinOrgID.splice(0);
    }

    this.selectAvailableRoom();
  }

  roomCkChange(val: any) {
    if (val.length > 0) {
      let lastId = val[val.length - 1];
      let orgId = this.availableRoomList.filter((x) => x.id == lastId)[0].organId;
      let ids = this.availableRoomList.filter((t) => t.organId == orgId && t.id != lastId).map((x) => x.id);

      ids.forEach((item) => {
        let _index = this.ruleForm.ckIds.indexOf(item);
        if (_index > -1) {
          this.ruleForm.ckIds.splice(_index, 1);
        }
      });
    }
  }

  selectDateClear() {
    if (!this.ruleForm.singleDate) {
      this.availableRoomList.splice(0);
    }
  }

  anchorClose() {
    this.ruleForm.callerId = null;
    this.ruleForm.callerName = '';
    (this.$refs.ruleForm as any).validateField('callerId');
  }

  anchorCallback(value: any) {
    this.ruleForm.callerId = value.employeeID;
    this.ruleForm.callerName = value.employeeName;
    (this.$refs.ruleForm as any).validateField('callerId');
  }

  attendeeClose(value: number) {
    let _index = this.ruleForm.attendees.findIndex((x) => x.key == value);
    this.ruleForm.attendees.splice(_index, 1);

    (this.$refs.ruleForm as any).validateField('attendeeProp');
  }

  attendeeCallback(value: any) {
    value.forEach((item) => {
      let _index = this.ruleForm.attendees.findIndex((x) => x.key == item.employeeID);
      if (_index <= -1) {
        this.ruleForm.attendees.push({ key: item.employeeID, value: item.employeeName, positionName: item.positionName });
      }
    });

    (this.$refs.ruleForm as any).validateField('attendeeProp');
  }

  informerClose(value: number) {
    let _index = this.ruleForm.informers.findIndex((x) => x.key == value);
    this.ruleForm.informers.splice(_index, 1);
  }

  informerCallback(value: any) {
    value.forEach((item) => {
      let _index = this.ruleForm.informers.findIndex((x) => x.key == item.employeeID);
      if (_index <= -1) {
        this.ruleForm.informers.push({ key: item.employeeID, value: item.employeeName, positionName: item.positionName });
      }
    });
  }

  resetForm() {
    this.ruleForm.joinOrgID.splice(0);
    this.availableRoomList.splice(0);
    this.ruleForm.attendees.splice(0);
    this.ruleForm.informers.splice(0);
    this.ruleForm.endTime = '';

    (this.$refs.ruleForm as any).resetFields();
  }

  async open() {
    await MeetingModule.get({ id: this.id });
    let _model = MeetingModule.currentInfo;
    Object.assign(this.ruleForm, _model);
    this.ruleForm.ckIds = _model.relationRooms.map((x) => {
      return x.id;
    });

    if (_model.meetingType == 1) {
      this.ruleForm.joinOrgID = [_model.approvalOrgID];
      this.showCkJoinOrg = true;
    } else {
      this.ruleForm.joinSingleOrgID = _model.approvalOrgID;
      this.showCkJoinOrg = false;
    }

    await this.selectAvailableRoom();
    await BaseDataModule.getOftenUsedList({ subscriberId: this.currentUser.employeeID });
  }

  mounted() {
    setTimeout(async () => {
      await BaseDataModule.getMeetingType();
      await BaseDataModule.getCycleRepeatMode();
      await BaseDataModule.getAllOrgansOfTheGroup();
      await BaseDataModule.getSameDeptStaffData({ deptId: this.currentUser.deptID });
    }, 1000);
  }

  close() {
    this.resetForm();
    this.$emit('update:isShow', false);
  }

  attendeePropVerify(rule: any, value: string, callback: any) {
    if (this.ruleForm.attendees.length <= 0) callback(new Error('请选择参会人'));
    else callback();
  }

  verifyBeginTime(rule: any, value: string, callback: any) {
    if (!value || !this.ruleForm.endTime) callback(new Error('请选择开始时间和结束时间'));
    else callback();
  }

  verifyJoinOrgID(rule: any, value: any[], callback: any) {
    if (value.length <= 0 && !this.ruleForm.joinSingleOrgID) callback(new Error('请选择参会基地'));
    else callback();
  }

  verifyRules: any = {
    beginTime: {
      required: true,
      trigger: 'change',
      validator: this.verifyBeginTime
    },
    callerId: {
      required: true,
      trigger: 'change',
      validator: (rule: any, value: number, callback: any) => {
        if (!value || value <= 0) callback(new Error('请选择主持人'));
        else callback();
      }
    },
    approvalOrgID: {
      required: true,
      trigger: 'change',
      validator: (rule: any, value: number, callback: any) => {
        if (!value || value <= 0) callback(new Error('请选择会议审批'));
        else callback();
      }
    },
    joinOrgID: {
      required: true,
      trigger: 'change',
      validator: this.verifyJoinOrgID
    },
    attendeeProp: {
      required: true,
      trigger: 'change',
      validator: this.attendeePropVerify
    }
  };
}
