





























































































import { Component, Vue } from "vue-property-decorator";
import BaseDataModule from "@/store/modules/base-data";
import MeetingModule from "@/store/modules/meeting";
import MeetingListQuery from "@/store/entities/meeting-list-query";
import MeetingInfo from "@/store/entities/meeting-info";
import EventView from '@/views/components/EventView.vue'
import EditSingleDraft from '@/views/query-meeting/edit-single-draft.vue'
import EditCycleDraft from '@/views/query-meeting/edit-cycle-draft.vue'
import { MeetingModeEnum } from '@/store/entities/state-enum'

@Component({ components: { EventView, EditSingleDraft, EditCycleDraft } })
export default class MeetingList extends Vue {
  query: MeetingListQuery = new MeetingListQuery();
  currentPage: number = MeetingModule.currentPage;
  pageSize: number = MeetingModule.pageSize;
  meetingId: number = 0;
  dateValue: string[] = [];

  dataLoading: boolean = true;
  isShowDetail: boolean = false;
  isShowSingleEdit: boolean = false;
  isShowCycleEdit: boolean = false;

  get meetingModeList() {
    return BaseDataModule.meetingModeList;
  }

  get meetingTypeList() {
    return BaseDataModule.meetingTypeList;
  }

  get meetingStatusList() {
    return BaseDataModule.meetingStatusList;
  }

  get list() {
    return MeetingModule.list;
  }

  get totalCount() {
    return MeetingModule.totalCount;
  }

  isEditable(row: any) {
    /* 草稿状态和单次会议才可以编辑会议*/
    if (row.status === 1 && row.masterMeetingId === 0) {
      /* 本人才能编辑会议 */
      let _currentUser = BaseDataModule.currentUser;
      return row.subscriberId == Number(_currentUser.employeeID);
    }

    return false;
  }

  editView(row: any) {
    this.meetingId = row.id;
    if (row.meetingMode === 1) {
      this.isShowSingleEdit = true;
      this.isShowCycleEdit = false;
    } else {
      this.isShowCycleEdit = true;
      this.isShowSingleEdit = false;
    }
  }

  seeView(id: number) {
    this.isShowDetail = true;
    this.meetingId = id;
  }

  disabledStartDate(value: Date) {
    if (!this.query.endDate) return false;
    else {
      let ed = new Date(this.query.endDate);
      return value > ed;
    }
  }

  disabledEndDate(value: Date) {
    if (!this.query.startDate) return false;
    else {
      let sd = new Date(this.query.startDate);
      return value < sd;
    }
  }

  beginDateTime(row: MeetingInfo) {
    if (!row.repeatStartDate) {
      return `${row.singleDate} ${row.beginTime}`;
    } else {
      return `${row.repeatStartDate} ${row.beginTime}`;
    }
  }

  endDateTime(row: MeetingInfo) {
    if (!row.repeatEndDate) {
      return `${row.singleDate} ${row.endTime}`;
    } else {
      return `${row.repeatEndDate} ${row.endTime}`;
    }
  }

  resetSearch() {
    (this.$refs.searchMeeting as any).resetFields();
    this.query.startDate = "";
    this.query.endDate = "";
    this.dateValue.splice(0);
    this.searchData();
  }

  async searchData() {
    this.currentPage = 1;
    MeetingModule.setPage(1);
    await this.getData();
  }

  async sortChange(sort: any) {
    if (!sort.order) {
      this.query.sorting = ""
    } else if (!sort.column.sortBy) {
      this.query.sorting = `${sort.prop} ${sort.order}`.replace("ending", "");
    } else {
      this.query.sorting = `${sort.column.sortBy} ${sort.order}`.replace("ending", "");
    }
    await this.getData();
  }

  async pageChange(page: number) {
    MeetingModule.setPage(page);
    await this.getData();
  }

  async sizeChange(count: number) {
    MeetingModule.setPageSize(count);
    await this.getData();
  }

  async getData() {
    this.dataLoading = true;

    if (this.dateValue && this.dateValue.length > 1) {
      this.query.startDate = this.dateValue[0] ?? "";
      this.query.endDate = this.dateValue[1] ?? "";
    }
    this.query.meetingMode = MeetingModeEnum.Single;
    await MeetingModule.getAll(this.query).finally(() => {
      setTimeout(() => {
        this.dataLoading = false;
      }, 300);
    });
  }

  async mounted() {
    let userId = this.$route.query.id;
    if (userId) {
      await BaseDataModule.getEmployeeById({ employeeId: userId });
    }

    this.getData();
    await BaseDataModule.getMeetingType();
    await BaseDataModule.getMeetingApprovalState();
    await BaseDataModule.getMeetingMode();
    await BaseDataModule.getOrganizationList();
  }

}
