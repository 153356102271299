





















































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseDataModule from '@/store/modules/base-data';
import MeetingModule from '@/store/modules/meeting';
import MeetingInfo from '@/store/entities/meeting-info';
import Anchor from '@/views/components/Anchor.vue';
import Collective from '@/views/components/Collective.vue';
import OrganInfo from '@/store/entities/organ-info';
import { StateEnum, CycleModeEnum, MeetingTypeEnum } from '@/store/entities/state-enum';
import { datetimeFormat } from '@/util/date-util';

@Component({
  components: {
    Anchor,
    Collective
  }
})
export default class CycleReserve extends Vue {
  @Prop({ default: false }) isShow!: boolean;
  @Prop({ type: Number, required: true }) id!: number;

  ruleForm: MeetingInfo = new MeetingInfo();
  state: any = StateEnum;

  showAlert: boolean = false;
  showAnchor: boolean = false;
  showCollective: boolean = false;
  showInformer: boolean = false;
  arLoading: boolean = false;
  submitLoading: boolean = false;
  saveLoading: boolean = false;

  get currentUser() {
    return BaseDataModule.currentUser;
  }

  get allRoomList() {
    return MeetingModule.allRoomList;
  }

  get orgList() {
    return BaseDataModule.orgList;
  }

  get meetingTypeList() {
    return BaseDataModule.meetingTypeList;
  }

  get cycleRepeatList() {
    let week = CycleModeEnum.Week;
    return BaseDataModule.cycleRepeatList.filter((x) => x.key == week);
  }

  async mounted() {
    await MeetingModule.getAllRoomList({ type: MeetingTypeEnum.Video, name: '' });
  }

  async save(status: StateEnum) {
    (this.$refs.ruleFormRef as any).validate((valid: boolean) => {
      if (valid) {
        if (this.ruleForm.attendees.length <= 1) {
          this.$confirm('建议您完整选择本次会议的参会人，以便排查您召集的参会人是否存在日程冲突...', '提示', {
            confirmButtonText: '选择无误,继续提交',
            cancelButtonText: '我再想想',
            type: 'warning'
          })
            .then(() => {
              this.saveSpecificOperation(status);
            })
            .catch(() => {
              return;
            });
        } else {
          this.saveSpecificOperation(status);
        }
      }
    });
  }

  async saveSpecificOperation(status: StateEnum) {
    let _message = '';
    if (status == StateEnum.Draft) {
      this.saveLoading = true;
      _message = '确认保存草稿？';
    } else {
      this.submitLoading = true;
      _message = '确认提交审批？';
    }

    this.ruleForm.status = status;
    this.ruleForm.lastModifierUserId = Number(this.currentUser.employeeID);
    this.ruleForm.subscriberId = Number(this.currentUser.employeeID);
    this.ruleForm.subscriberName = this.currentUser.employeeName;
    this.ruleForm.approvalOrgName = (this.orgList.find((x) => x.id === this.ruleForm.approvalOrgID) as OrganInfo)?.shortName;
    this.ruleForm.relationRooms = this.allRoomList
      .filter((x) => this.ruleForm.ckIds.indexOf(x.id) > -1)
      .map((t) => ({
        key: t.id,
        value: t.name
      }));

    this.$confirm(_message, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(async () => {
        this.ruleForm.meetingMode = 2;
        await MeetingModule.atTheSameTime(this.ruleForm).then(async (res) => {
          if (res) {
            let remindMsg = `${res}在此时间段（${this.ruleForm.beginTime}~${this.ruleForm.endTime}）需要参加其他会议（时间冲突）,是否继续提交？`;
            this.$confirm(remindMsg, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(async () => {
                await this.editMeeting();
              })
              .catch(() => {
                this.saveLoading = false;
                this.submitLoading = false;
              });
          } else {
            await this.editMeeting();
          }
        });
      })
      .catch(() => {
        this.saveLoading = false;
        this.submitLoading = false;
      });
  }

  async editMeeting() {
    await MeetingModule.edit(this.ruleForm)
      .then((res) => {
        this.$message.success('操作成功');
        this.$emit('refreshData');
        this.close();
      })
      .finally(() => {
        this.saveLoading = false;
        this.submitLoading = false;
      });
  }

  async meetingTypeChange(val: number) {
    this.arLoading = true;

    let _orgId = 0;
    if (val != 1) _orgId = this.ruleForm.approvalOrgID;

    await MeetingModule.getAllRoomList({ type: val, name: '', orgId: _orgId }).finally(() => {
      setTimeout(() => {
        this.arLoading = false;
      }, 600);
    });
  }

  async approvalChange(val: number) {
    this.arLoading = true;

    let _orgId = 0;
    if (this.ruleForm.meetingType != 1) _orgId = val;

    await MeetingModule.getAllRoomList({ type: this.ruleForm.meetingType, name: '', orgId: _orgId }).finally(() => {
      setTimeout(() => {
        this.arLoading = false;
      }, 600);
    });
  }

  async open() {
    await MeetingModule.get({ id: this.id });
    let _model = MeetingModule.currentInfo;
    Object.assign(this.ruleForm, _model);
    this.ruleForm.ckIds = _model.relationRooms.map((x) => {
      return x.id;
    });
    this.ruleForm.weeks = this.ruleForm.repeatIntervalDays.split(',').map((t) => {
      return Number(t);
    });
  }

  roomCkChange(val: any) {
    if (val.length <= 0) return;

    let lastId = val[val.length - 1];
    let orgId = this.allRoomList.filter((x) => x.id == lastId)[0].organId;
    let ids = this.allRoomList.filter((t) => t.organId == orgId && t.id != lastId).map((x) => x.id);

    ids.forEach((item) => {
      let _index = this.ruleForm.ckIds.indexOf(item);
      if (_index > -1) {
        this.ruleForm.ckIds.splice(_index, 1);
      }
    });
  }

  anchorClose() {
    this.ruleForm.callerId = null;
    this.ruleForm.callerName = '';
    (this.$refs.ruleFormRef as any).validateField('callerId');
  }

  anchorCallback(value: any) {
    this.ruleForm.callerId = value.employeeID;
    this.ruleForm.callerName = value.employeeName;
    (this.$refs.ruleFormRef as any).validateField('callerId');
  }

  attendeeClose(value: number) {
    let _index = this.ruleForm.attendees.findIndex((x) => x.key == value);
    this.ruleForm.attendees.splice(_index, 1);
  }

  attendeeCallback(value: any) {
    value.forEach((item) => {
      let _index = this.ruleForm.attendees.findIndex((x) => x.key == item.id);
      if (_index <= -1) {
        this.ruleForm.attendees.push({ key: item.id, value: item.name, positionName: '' });
      }
    });

    (this.$refs.ruleFormRef as any).validateField('attendeeProp');
  }

  informerClose(value: number) {
    let _index = this.ruleForm.informers.findIndex((x) => x.key == value);
    this.ruleForm.informers.splice(_index, 1);
  }

  informerCallback(value: any) {
    value.forEach((item) => {
      let _index = this.ruleForm.informers.findIndex((x) => x.key == item.id);
      if (_index <= -1) {
        this.ruleForm.informers.push({ key: item.id, value: item.name, positionName: '' });
      }
    });
  }

  resetForm() {
    this.ruleForm.attendees.splice(0);
    this.ruleForm.informers.splice(0);
    this.ruleForm.endTime = '';

    (this.$refs.ruleFormRef as any).resetFields();
  }

  close() {
    this.resetForm();
    this.$emit('update:isShow', false);
  }

  disabledStart(dt: Date) {
    let now = datetimeFormat(new Date()) + ' 00:00:00';
    let dNow = new Date(now);

    if (this.ruleForm.repeatEndDate) return !(dt >= dNow && dt <= new Date(this.ruleForm.repeatEndDate + ' 00:00:00'));
    else return dt < dNow;
  }

  disabledEnd(dt: Date) {
    let now = datetimeFormat(new Date()) + ' 00:00:00';
    let dNow = new Date(now);

    if (this.ruleForm.repeatStartDate) return dt < new Date(this.ruleForm.repeatStartDate + ' 00:00:00');
    else return dt < dNow;
  }

  attendeePropVerify(rule: any, value: string, callback: any) {
    if (this.ruleForm.attendees.length <= 0) callback(new Error('请选择参会人'));
    else callback();
  }

  weeksVerify(rule: any, value: string, callback: any) {
    if (this.ruleForm.weeks.length <= 0) callback(new Error('请选择重复星期'));
    else callback();
  }

  verifyRepeatStartDate(rule: any, value: string, callback: any) {
    if (!value || !this.ruleForm.repeatEndDate) callback(new Error('请选择开始日期和结束日期'));
    else callback();
  }

  verifyBeginTime(rule: any, value: string, callback: any) {
    if (!value || !this.ruleForm.endTime) callback(new Error('请选择开始时间和结束时间'));
    else callback();
  }

  verifyRules: any = {
    beginTime: {
      required: true,
      trigger: 'change',
      validator: this.verifyBeginTime
    },
    repeatStartDate: {
      required: true,
      trigger: 'change',
      validator: this.verifyRepeatStartDate
    },
    callerId: {
      required: true,
      trigger: 'change',
      validator: (rule: any, value: number, callback: any) => {
        if (!value || value <= 0) callback(new Error('请选择主持人'));
        else callback();
      }
    },
    approvalOrgID: {
      required: true,
      trigger: 'change',
      validator: (rule: any, value: number, callback: any) => {
        if (!value || value <= 0) callback(new Error('请选择会议审批'));
        else callback();
      }
    },
    cycleRepeatMode: {
      required: true,
      trigger: 'change',
      validator: (rule: any, value: number, callback: any) => {
        if (!value || value <= 0) callback(new Error('请选择会议重复模式'));
        else callback();
      }
    },
    weeks: {
      require: true,
      trigger: 'change',
      validator: this.weeksVerify
    },
    attendeeProp: {
      required: true,
      trigger: 'change',
      validator: this.attendeePropVerify
    }
  };
}
